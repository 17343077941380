import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components//atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const IconNavigationBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} centerHeadline="true" blockPadding="py-20">
      <div className="flex flex-row flex-wrap pt-20 -mx-grid">
        {block.icon_nav_items?.length > 0 &&
          block.icon_nav_items.map((item, index) => (
            <div className="w-full md:w-1/3 px-grid">
              <div className="pb-20 lg:px-14 md:pb-0">
                <Link className="group" link={item.link}>
                  <Image
                    className="object-contain object-center w-full h-20 text-white transition-transform duration-300 transform fill-white group-hover:animate-zoom-in-out"
                    image={item.icon}
                  />
                  <p className="pt-5 prose prose-xl text-center md:pt-10 max-w-none">
                    {item.text}
                  </p>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default IconNavigationBlock
